/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::selection {
  background-color: $danger;
  color: $white;
}


.has-sidemenu{
  scroll-padding-top: $fancynav-height;
  &.has-fancynav-top{
    scroll-padding-top: $fancynav-height;
  }
  @include media-breakpoint-up(lg) {
    scroll-padding-top: 0;
  }
}
.bootstrap-nav{
  scroll-padding-top: $fancynav-height;
}




body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  // scroll-behavior: smooth;
}


pre,
code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

section {
  position: relative;
  padding-top: map_get($spacers, 6);
  padding-bottom: map_get($spacers, 6);

  @include media-breakpoint-up(lg) {
    padding-top: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 8);
  }
}

//setting up font family for bootstrap components
input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: $font-family-secondary;
}

pre {
  margin: 0;
  transform: translate3d(0, 0, 0);
}

strong {
  font-weight: $font-weight-semibold;
}

label {
  margin-bottom: map-get($spacers, 2);
}

pre,
//-code,
kbd,
samp {
  font-size: $font-size-sm;
}

code{
  font-size: 87.5%;
}

ol,
ul {
  padding-left: 2.5rem;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--#{$prefix}heading-color);
}